<template>
    <LServiceSection />
</template>

<script>
import LServiceSection from "../../components/Landing/LServiceSection.vue"

export default {
    name: "ServicePage",
    components: { LServiceSection },
}
</script>
<style scoped></style>
