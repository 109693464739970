<template>
    <section class="service">
        <LVideo class="service-video" :mp4="vid" />
        <div class="service__container container">
            <div class="service-top">
                <p class="service-top__meta">Meta</p>
                <p class="service-top__force">Force</p>
            </div>
            <h1 class="service__title">{{ $t("service.title") }}</h1>
            <p class="service__desc">{{ $t("service.desc") }}</p>
        </div>
    </section>
</template>

<script>
import LVideo from "./partials/LVideo.vue"
export default {
    data() {
        return {
            vid: "/assets/bg-animation.mp4",
        }
    },
    name: "LServiceSection",
    components: {
        LVideo,
    },
}
</script>

<style scoped lang="scss">
@import "~@/assets/scss/mixins";
@font-face {
    font-display: swap;
    font-family: "Dodger-super-Italic";
    src: url("~@/assets/Dodger-Super-Italic.woff2") format("woff2");
    font-style: italic;
    font-weight: 400;
}
.service {
    position: relative;
    background-position: 100% 150%;
    height: 100vh;
    padding-top: 232px;
    text-align: center;
    @include tablet {
        padding-top: 180px;
    }
    @include mobile {
        padding-bottom: 80px;
        display: flex;
        align-items: center;
        padding-top: 0;
    }

    &-video {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
    &__container {
        position: relative;
        z-index: 2;
    }
    &-top {
        margin-bottom: 31px;
        @include mobile {
            margin-bottom: 15p;
        }
        &__meta,
        &__force {
            font-family: "Dodger-super-Italic", sans-serif;
            font-style: italic;
        }
        &__meta {
            font-weight: 400;
            font-size: 28px;
            line-height: 15px;
            text-transform: uppercase;
            @include mobile {
                font-size: 20px;
                line-height: 10px;
            }
            @include mobile-s {
                font-size: 18px;
            }
        }
        &__force {
            font-weight: 400;
            font-size: 50px;
            line-height: 44px;
            text-transform: uppercase;
            @include mobile {
                font-size: 40px;
            }
            @include mobile-s {
                font-size: 35px;
                line-height: 30px;
            }
        }
    }
    &__title {
        font-weight: 700;
        font-size: 40px;
        line-height: 47px;
        text-transform: uppercase;
        margin-bottom: 10px;
        @include mobile {
            font-size: 30px;
            line-height: 37px;
        }
        @include mobile-s {
            font-size: 20px;
            line-height: 27px;
        }
    }
    &__desc {
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        @include mobile {
            font-size: 18px;
            line-height: 20px;
        }
        @include mobile-s {
            font-size: 14px;
            line-height: 18px;
        }
    }
}
</style>
